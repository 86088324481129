.FileGalleryView {
    .modal {
        display: block;
        position: fixed;
        z-index: 1000;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        overflow: hidden;
    }

    .modal-content {
        margin: 5% auto;
        background-color: rgb(36, 34, 36);
        width: 70%;
        max-width: 1200px;
        padding: 50px;
        position: relative;
        overflow: hidden;
        color: #f1f1f1;
    }

    .close {
        color: #f1f1f1;
        position: absolute;
        top: 10px;
        right: 15px;
        font-size: 28px;
        font-weight: bold;
        cursor: pointer;
    }

    .close:hover,
    .close:focus {
        color: #ddd;
        text-decoration: none;
        cursor: pointer;
    }

    .gallery-media {
        max-width: 100%;
        height: auto;
        width: auto;
        display: block;
        margin: 0 auto;
    }

    .gallery-image-link {
        display: block;
        max-width: 100%;
    }

    .gallery-index {
        position: absolute;
        top: 10px;
        left: 10px;
        color: #f1f1f1;
        padding: 5px;
    }

    .image-gallery-index {
        display: none;
    }

    >button {
        color: $button-text-color;
        background-color: $color-template;
        text-transform: capitalize;
        border-radius: 20px;
        font-size: 10px;
    }
    >span{
        img{
            width: 60px;
            height: auto;
            margin: auto;
            border-radius: unset;
        }
        
    }

    .image-gallery-play-button {
        padding: 0px !important;
    }

    .image-gallery-fullscreen-button {
        padding: 0px !important;
    }

    .image-gallery-thumbnail {
        margin-top: 10px;
        border-radius: 12px;
    }

    .image-gallery-thumbnail-image {
        margin-top: 0px;
    }
}